import { FC } from "react";
import { wrapClick } from "utils";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import parseHtml from "html-react-parser";
import { EXAMINATION_GRADING, ExaminationType } from "utils/general";

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  values: {
    programmeInfo: {
      programme: any;
      tracks: ("SEMESTER" | "TRIMESTER" | "QUARTERLY" | "MODULAR")[];
      offerings: {
        campus: any;
        types: ("DAY" | "EVENING" | "WEEKEND")[];
      }[];
      requirementTypes: ("ALEVEL" | "OLEVEL" | "WASSCE" | "SSSCE" | "HND" | "DIPLOMA" | "LLB" | "DEGREE" | "COTVET")[];
      hasAdmissionQuota: boolean;
      admissionQuota: number;
    };
    requirementsInfo: {
      requirements: {
        description: string;
        specification: {
          mandatory: boolean;
          requiredGrade: number;
          subject: string;
        }[];
        type: ("ALEVEL" | "OLEVEL" | "WASSCE" | "SSSCE" | "HND" | "DIPLOMA" | "LLB" | "DEGREE" | "COTVET");
        defaultMinimumGrade?: number;
        numberOfPassedSubjects?: number;
      }[];
    };
    examinationInfo: {
      requiresExamination: boolean;
      requiresInterview: boolean;
      maximumInterviewScore: number;
      maximumExaminationScore: number;
      requiredExaminationScore: number;
      requiredInterviewScore: number;
      examinationScoreWeight: number;
      interviewScoreWeight: number;
    };
  };
}


const summaries = [
  {
    name: "Programme Info",
    description: "Information about programme.",
    accessor: "programmeInfo",
    href: 0,
    Component: ({ data }: any) => {

      const GET_PROGRAMME = gql`
      query GetProgramme($filter: ProgrammeFilter!) {
        item:getProgramme(filter: $filter) {
          _id
          name
          code
        }
      }
      `;

      const { data: programme } = useQuery(GET_PROGRAMME, {
        variables: {
          "filter": {
            "_id": {
              "eq": data?.programme?._id
            }
          }
        }
      });

      const GET_CAMPUSES = gql`
      query GetCampuses($filter: CampusFilter) {
        items: getCampuses(filter: $filter) {
          _id
          name
        }
      }
      `;

      const campusesValues = data?.offerings?.map((offering: any) => offering?.campus) || [];

      const { data: campuses } = useQuery(GET_CAMPUSES, {
        variables: {
          "filter": {
            "_id": {
              "in": campusesValues
            }
          }
        }
      });

      return (
        (
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3">
            <div>
              <div className="grid grid-cols-3 gap-6">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Programme Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {programme?.item?.code || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Programme Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {programme?.item?.name || "N/A"}
                  </div>
                </div>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    Requirement Types
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.requirementTypes?.join(", ") || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Programme Duration (Months)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.duration || "N/A"}
                  </div>
                </div>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    Available Tracks
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.tracks?.join(", ") || "N/A"}
                  </div>
                </div>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    Admission Quota
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.admissionQuota || "N/A"}
                  </div>
                </div>

                <div className="col-span-3">
                  <span className="block text-sm font-light text-gray-700">
                    Available Offerings
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Campus
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900 w-[15%]"
                          >
                            Day
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900 w-[15%]"
                          >
                            Evening
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center w-[15%]"
                          >
                            Weekend
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {data?.offerings?.map((step: any, idx: string) => (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{campuses?.items?.find((item: any) => item?._id === step?.campus).name || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">{step?.types?.includes("DAY") ? "Yes" : "No"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">{step?.types?.includes("EVENING") ? "Yes" : "No"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">{step?.types?.includes("WEEKEND") ? "Yes" : "No"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      );
    }
  },
  {
    name: "Requirements Info",
    description: "Details",
    accessor: "requirementsInfo",
    href: 1,
    Component: ({ data, config: { moneyFormat } }: any) => (
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3">
        <div className="divide-y divide-gray-200 space-y-6">
          {data?.requirements?.map((requirement: any) => (
            <div className="grid grid-cols-1 gap-6 pt-6 first:pt-0">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {_.startCase(requirement?.type || "") || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Description
                </span>
                <div className="mt-1 block w-full sm:text-sm rounded border border-gray-200 p-3">
                  {parseHtml(requirement?.description || "N/A")}
                </div>
              </div>

              {
                (!!requirement?.defaultMinimumGrade && _.isNumber(requirement?.defaultMinimumGrade)) && (
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Default Minimum Grade
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {
                        EXAMINATION_GRADING?.[requirement?.type as ExaminationType]?.find((grade) => grade.value === requirement?.defaultMinimumGrade)?.label || "N/A"
                      }
                    </div>
                  </div>
                )
              }

              {
                (!!requirement?.numberOfPassedSubjects && _.isNumber(requirement?.numberOfPassedSubjects)) && (
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Minimum Number of Passed Subjects
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {
                        _.toString(requirement?.numberOfPassedSubjects) || "N/A"
                      }
                    </div>
                  </div>
                )
              }

              {["ALEVEL", "OLEVEL", "WASSCE", "SSSCE"].includes(requirement?.type) && (
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Specifications
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]"
                          >
                            Subject
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-[20%]"
                          >
                            Required Grade
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-[20%]"
                          >
                            Mandatory
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {requirement?.specifications?.map((step: any, idx: string) => (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step?.subject || "N/A"} </td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{EXAMINATION_GRADING?.[requirement?.type as ExaminationType]?.find((grade) => `${grade?.value}` === `${step?.requiredGrade}`)?.label || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{(step?.mandatory === true ? "Yes" : "No") || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  },
  {
    name: "Extra Examination Info",
    description: "Details",
    accessor: "examinationInfo",
    href: 2,
    Component: ({ data }: any) => (
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3">

        <div className="">
          <div className="grid grid-cols-3 gap-6">

            <div>
              <span className="block text-sm font-light text-gray-700">
                Requires Entrance Examination ?
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.requiresExamination ? "Yes" : "No"}
              </div>
            </div>

            {data?.requiresExamination && (
              <>
                <div className="col-start-1">
                  <span className="block text-sm font-light text-gray-700">
                    Maximum Examination Score
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.maximumExaminationScore || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Required Examination Score
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.requiredExaminationScore || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Examination Score Weight
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.examinationScoreWeight || "N/A"}%
                  </div>
                </div>
              </>
            )}

            <div>
              <span className="block text-sm font-light text-gray-700">
                Requires Entrance Interview ?
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.requiresInterview ? "Yes" : "No"}
              </div>
            </div>

            {data?.requiresInterview && (
              <>
                <div className="col-start-1">
                  <span className="block text-sm font-light text-gray-700">
                    Maximum Interview Score
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.maximumInterviewScore || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Required Interview Score
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.requiredInterviewScore || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Interview Score Weight
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.interviewScoreWeight || "N/A"}%
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
];


const SummaryPage: FC<SummaryPageProps> = ({ values, handlePrevious, handleStep, submitLoading, ...form }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto">
        {summaries.map(({ Component, ...summary }, idx) => (
          <Disclosure as="div" defaultOpen={true} key={idx} className={idx === 0 ? " p-4" : " p-4"}>
            {({ open }) => (
              <>
                <Disclosure.Button as="div" className="flex justify-between items-center cursor-pointer">
                  <div>
                    <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">{summary.name}</h3>
                    <p className="mt-1 text-xs text-gray-500">{summary.description}</p>
                  </div>
                  <div>
                    {open ? (
                      <ChevronUpIcon
                        className="h-6 w-6 text-gray-400 dark:text-gray-500"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="h-6 w-6 text-gray-400 dark:text-gray-500"
                      />
                    )
                    }
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel as="div" className="mt-6">
                  <Component data={(values as any)[summary.accessor]} config={currentConfig} />
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => handleStep(summary.href)}
                        className="ml-3 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-gray-600 border-gray-600 hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        <PencilSquareIcon className="-ml-0.5 mr-1.5 h-4 w-4" aria-hidden="true" />
                        Edit
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          disabled={submitLoading}
          className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SummaryPage;