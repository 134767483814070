import { Bars4Icon, BookOpenIcon, EnvelopeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { FC, Fragment, useState } from 'react'
import { Link, Navigate, Outlet, useLocation } from 'react-location'
import { classNames, wrapClick } from 'utils';
import { clearAuth, currentTokenVar, currentUserVar } from 'apollo/cache/auth';

import { Avatar } from 'components/core';
import _ from "lodash";
import routes from 'router/routes';
import { useReactiveVar } from "@apollo/client";
import { withRoutePermissions } from 'router/filter';

const NAV_SECTIONS = ["General", "Field Reports", "Structure", "Customer Relation", "Configuration", "Users & Profiles"];

const AppLayout: FC = () => {
  const location = useLocation()
  const currentUser = useReactiveVar(currentUserVar);
  const currentToken = useReactiveVar(currentTokenVar)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = _
    .chain(routes)
    .filter(["meta.layout", "App"])
    .thru(withRoutePermissions(currentUser?.permissions ?? []))
    .filter((item) => !!item.sidebar)
    .value()

  if (!currentToken) {
    return <Navigate
      to={"/signin"}
      search={{ redirect: location.current.href }}
      replace
    />
  }
  return (
    <div className='h-screen'>
      <div className="h-full flex">
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-4">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="pt-5 pb-4">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src={require("../../assets/logo.png")}
                      alt="SUBS"
                    />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link
                          key={item.sidebar?.label}
                          to={item.path}
                          className="group p-2 rounded flex items-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.sidebar && (<item.sidebar.icon
                            className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />)}
                          {item.sidebar?.label}
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <Link to="/#" className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <Avatar
                          src={currentUser?.profileImageUrl}
                          alt={currentUser?.name || "N A"}
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{currentUser?.name}</p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Account Settings</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col border-r border-gray-200 bg-white w-64">
            <div className="flex flex-shrink-0 items-center border-b border-gray-200 flex-row">
              <div className='bg-primary-600 p-4 h-full aspect-[1/1] flex-shrink-0'>
                <img
                  className="h-9 w-9 "
                  src={require("../../assets/logo.png")}
                  alt="SUBS"
                />
              </div>
              <div className="p-4">
                <p className="text-sm text-gray-900">Setup Management Console</p>
                {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
              </div>
            </div>
            <div className="py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar">
              <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
                {_.map(_.filter(navigation, ["sidebar.level", "primary"]), (item) => {
                  return (
                    <Link
                      key={item.sidebar?.label}
                      to={item.path}
                      getActiveProps={() => ({
                        className: "bg-gray-100 text-gray-900"
                      })}
                      getInactiveProps={() => ({
                        className: "text-gray-600 hover:bg-gray-50 hover:text-gray-800"
                      })}
                      className='group flex items-center px-2 py-2 text-sm rounded'
                    >
                      {item.sidebar?.icon && (
                        <item.sidebar.icon
                          className={classNames(
                            'mr-3 flex-shrink-0 h-6 w-6 opacity-90'
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {item.sidebar?.label}
                    </Link>
                  )
                })}
              </nav>
            </div>
            <div className="py-4 flex flex-shrink-0 flex-col">
              <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
                <a
                  href="https://docs.subsenterprise.com/gimpa-online-admission-system-console-docs/pages/admissions/export-admission-cycle"
                  target="_blank"
                  className="text-gray-600 hover:bg-gray-50 hover:text-gray-800 group flex items-center px-2 py-2 text-sm rounded w-full"
                  rel="noreferrer"
                >
                  <BookOpenIcon
                    className={classNames(
                      'mr-3 flex-shrink-0 h-6 w-6 opacity-90'
                    )}
                    aria-hidden="true"
                  />
                  Docs
                </a>
                <a
                  href="mailto:admissions@gimpa.edu.gh"
                  target="_blank"
                  className="text-gray-600 hover:bg-gray-50 hover:text-gray-800 group flex items-center px-2 py-2 text-sm rounded w-full"
                  rel="noreferrer"
                >
                  <EnvelopeIcon
                    className={classNames(
                      'mr-3 flex-shrink-0 h-6 w-6 opacity-90'
                    )}
                    aria-hidden="true"
                  />
                  Support
                </a>
                {_.map(_.filter(navigation, ["sidebar.level", "secondary"]), (item) => {
                  return (item.sidebar?.onClick && _.isFunction(item.sidebar?.onClick)) ? (
                    <button
                      key={item.sidebar?.label}
                      onClick={wrapClick(item.sidebar.onClick)}
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-800 group flex items-center px-2 py-2 text-sm rounded w-full"
                    >
                      {item.sidebar?.icon && (
                        <item.sidebar.icon
                          className={classNames(
                            'mr-3 flex-shrink-0 h-6 w-6 opacity-90'
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {item.sidebar?.label} 
                    </button>
                  ) : (
                    <Link
                      key={item.sidebar?.label}
                      to={item.path}
                      getActiveProps={() => ({
                        className: "bg-gray-100 text-gray-900"
                      })}
                      getInactiveProps={() => ({
                        className: "text-gray-600 hover:bg-gray-50 hover:text-gray-800"
                      })}
                      className='group flex items-center px-2 py-2 text-sm rounded'
                    >
                      {item.sidebar?.icon && (
                        <item.sidebar.icon
                          className={classNames(
                            'mr-3 flex-shrink-0 h-6 w-6 opacity-90'
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {item.sidebar?.label}
                    </Link>
                  )
                })}
              </nav>
            </div>

            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <Link to="/settings/#profile" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <button onClick={wrapClick(clearAuth)}>
                    <Avatar
                      src={currentUser?.profileImageUrl}
                      alt={currentUser?.name || "N A"}
                      size='xs'
                    />
                  </button>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-800 group-hover:text-gray-900 line-clamp-1">{currentUser?.name}</p>
                    <p className="text-xs font-medium text-gray-600 group-hover:text-gray-700 line-clamp-1">{currentUser?.email}</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          {/* Mobile top navigation */}
          <div className="lg:hidden">
            <div className="bg-primary-600 py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8">
              <div>
                <Avatar
                  src={currentUser?.profileImageUrl}
                  alt={currentUser?.name || "N A"}
                  size='xs'
                />
              </div>
              <div>
                <button
                  type="button"
                  className="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-primary-600 rounded text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars4Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          {/* Display content here */}
          <Outlet />

        </div>
      </div>
    </div>
  )
}

export default AppLayout;