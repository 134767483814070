import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Header, Shimmers, TableComponent } from 'components';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, classNames, withPermissions } from 'utils';
import config from 'config';
import { PlusIcon } from '@heroicons/react/24/outline';
import CreateStaffContainer from './create';
import UpdateStaffContainer from './update';
import ViewStaffContainer from './view';
import { CampusPicker, DepartmentPicker, ExportDataContainer, SchoolPicker } from 'containers';
import { Avatar, SearchSelectInput } from 'components/core';
import ActionButton, { Action } from 'components/buttons/action-button';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { currentConfigVar } from 'apollo/cache/config';
import lodash from 'lodash';
import dayjs from 'dayjs';
import SuspendStaffContainer from './suspend';
import toast from 'react-hot-toast';
import { currentUserVar } from 'apollo/cache/auth';

export const GET_USERS = gql`
  query GetUsers($filter: UserFilter, $pagination: Pagination, $search: SearchOperator, $sort: UserSort, $populate: [String]) {
    items: getUsers(filter: $filter, pagination: $pagination, search: $search, sort: $sort, populate: $populate) {
      _id
      code
      name
      phone
      email
      staffId
      profileImageUrl
      gender
      campus {
        _id
        code
      }
      school {
        _id
        code
        name
      }
      role
      meta {
        isFirstLogin
        isSuspended
        lastLoginAt
      }
      createdAt
      updatedAt
    }
    itemsCount: getUsersCount(filter: $filter, search: $search)
  }
`;
export const userRoles = [
  { label: 'Super User', value: 'SUDO', description: "" },
  { label: 'Dean', value: 'DEAN', description: "" },
  { label: 'Head of Department', value: 'DEPARTMENT_HEAD', description: "" },
  { label: 'Admissions Officer', value: 'ADMISSIONS_OFFICER', description: "" },
  { label: 'School Administrator', value: 'SCHOOL_ADMINISTRATOR', description: "" },
  { label: 'School Registrar', value: 'SCHOOL_REGISTRAR', description: "" },
  { label: 'Finance Officer', value: 'FINANCE_OFFICER', description: "" },
  { label: 'Campus Officer', value: 'CAMPUS_OFFICER', description: "" },
  { label: 'Campus Manager', value: 'CAMPUS_MANAGER', description: "" },
  { label: 'Observer', value: 'OBSERVER', description: "" },
];

const UsersPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [school, setSchool] = useUrlState("school");
  const [campus, setCampus] = useUrlState("campus");
  const [department, setDepartment] = useUrlState("department");
  const [role, setRole] = useUrlState<string>("role");
  const [suspend, setSuspend] = useUrlState<string>("suspend");

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        fields: ["name", "phone", "email", "staffId"]
      }
    } : {}),
    filter: {
      ...(role && { role: { eq: role } }),
      ...(school && { school: { eq: school } }),
      ...(campus && { campus: { eq: campus } }),
      ...(department && { department: { eq: department } }),
    },
    sort: {
      "meta": {
        "lastLoginAt": "descending"
      }
    },
    populate: ["school", "campus", "department"]
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_USERS, {
    variables,
    //pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only'
  });



  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      })
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "users:*", "users:create"])(
              <Menu>
                {({ open, close }) => (
                  <>
                    <div className="relative">
                      <div className="inline-flex divide-x divide-primary-700 rounded shadow-sm">
                        <div className="inline-flex divide-x divide-primary-700 rounded shadow-sm">
                          <button
                            type="button"
                            onClick={wrapClick(() => setModal("create"))}
                            className="inline-flex items-center rounded-l border border-transparent bg-primary-600 py-2 pl-3 pr-4 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                          >
                            <PlusIcon className="h-4 w-4" aria-hidden="true" />
                            <p className="ml-2.5  text-sm leading-4">Add New User</p>
                          </button>
                          <Menu.Button
                            className="inline-flex items-center rounded-l-none rounded-r bg-primary-600 p-2 text-sm leading-4  text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          >
                            <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right divide-y divide-gray-200 overflow-hidden rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userRoles.map((option) => (
                            <Menu.Item key={option.label}>
                              {({ active }) => (
                                <div
                                  className={
                                    classNames(
                                      active ? 'text-white bg-primary-600' : 'text-gray-900',
                                      'cursor-default select-none text-sm'
                                    )}
                                >
                                  <button
                                    type="button"
                                    className='flex flex-col w-full  p-4'
                                    onClick={wrapClick(() => {
                                      setRole(option.value);
                                      close();
                                      setModal("create");
                                    })}
                                  >
                                    <div className="flex justify-between">
                                      <p className={'font-normal'}>{option.label}</p>
                                    </div>
                                    <p className={classNames(active ? 'text-primary-200' : 'text-gray-500', 'mt-1 text-left text-xs')}>
                                      {option.description}
                                    </p>
                                  </button>
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </div>
                  </>
                )}
              </Menu>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'users'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="User"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SearchSelectInput
                  id="role"
                  labelHidden={true}
                  setFieldValue={(_field: string, value: string) => setRole(value)}
                  values={{ role }}
                  label={''}
                  options={userRoles.map((item) => ({ ...item, label: { title: item.label } }))}
                  placeholder='Select Role'
                />
                <CampusPicker
                  id="campus"
                  labelHidden={true}
                  rawId={true}
                  setFieldValue={(field: any, value: string) =>
                    setCampus(value)
                  }

                  values={{ campus }}
                />
                <SchoolPicker
                  id="school"
                  labelHidden={true}
                  rawId={true}
                  setFieldValue={(field: any, value: string) =>
                    setSchool(value)
                  }
                  filter={{
                    ...(school && { school: { eq: school } }),
                  }}
                  values={{ school }}
                />
                {school && (
                  <DepartmentPicker
                    id="department"
                    labelHidden={true}
                    rawId={true}
                    setFieldValue={(field: any, value: string) =>
                      setDepartment(value)
                    }
                    filter={{
                      ...(school && { school: { eq: school } }),
                    }}
                    values={{ department }}
                  />
                )}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Staff
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact
                </th>
                {!role && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Role
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Last Login At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 text-center bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {!school && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {!role && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={item?.name || "N A"}
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">{item?.name || "N/A"}</div>
                      <div className="text-gray-500 dark:text-gray-400">{item?.staffId || "N/A"}</div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.phone || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.email || "N/A"}</div>
                </td>
                {!role && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <span
                      className={classNames(
                        item?.role === "SuperAdmin" ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50` : "",
                        item?.role === "Admin" ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50` : "",
                        item?.role === "Supervisor" ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50` : "",
                        item?.role === "Reader" ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50` : "",
                        item?.role === "Queued" ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50` : "",
                        item?.role === "Success" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                        item?.role === "Reversed" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                        !item?.role ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                        <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                      </svg>
                      <span>
                        {item?.role.replace("_", " ") || "Unknown"}
                      </span>
                    </span>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.meta?.lastLoginAt ? dayjs(item?.meta?.lastLoginAt).format("DD/MM/YYYY hh:mm A") : "None"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-center">
                  <span
                    className={classNames(
                      item?.meta?.isSuspended ? ` bg-red-100 text-red-600` : "",
                      !item?.meta?.isSuspended ? ` bg-green-100 text-green-600` : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {item?.meta?.isSuspended ? "Suspended" : "Active"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "users:*", "users:update"])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                    {
                      (currentUser?._id !== item?._id) && (
                        <>
                          {withPermissions(["*:*", "users:*", "users:suspend"])(
                            <ActionButton
                              action='suspend'
                              onClick={dispatchAction(item?._id, "suspend")}
                              disabled={currentUser?._id === item?._id}
                            />
                          )}
                        </>
                      )
                    }



                  </div>
                </td>
              </tr>
            )}
          />
        </div>

      </div>

      {withPermissions(["*:*", "users:*", "users:create"])(
        <CreateStaffContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
          role={role}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "users:*", "users:update"])(
            <UpdateStaffContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "users:*", "users:suspend"])(
            <SuspendStaffContainer
              open={modal === "suspend"}
              setOpen={(val: boolean) => setModal(val ? "suspend" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewStaffContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default UsersPage;
