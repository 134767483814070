import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { CampusView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_CAMPUS = gql`
  query GetCampus($filter: CampusFilter!, $populate: [String]) {
    campus: getCampus(filter: $filter, populate: $populate) {
      _id
      code
      digitalAddress
      email
      name
      phone
      officeAddress
      createdBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        createdAt
        updatedAt
      }
      lastUpdatedBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  } 
`

export default function ViewCampusContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_CAMPUS, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["createdBy", "lastUpdatedBy"]
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Campus Information"
      description="Details of campus are shown below"
      renderActions={() => (
        <>
        {withPermissions(["*:*", "campuses:*", "campuses:update"])(
          <button
            type="button"
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(updateItem)}
          >
            Edit
          </button>
        )}
        </>
      )}
    >
      <CampusView
        campus={data?.campus}
      />
    </Modal>
  )
}